var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_index"},[_c('div',{staticClass:"banner"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"aboutIndex"},[_vm._m(0),_vm._m(1),_c('i'),_c('div',{staticClass:"dvantage"},[_c('div',{staticClass:"dvantage_content"},[_c('img',{attrs:{"src":require('@/assets/img/about/mb(3).png'),"alt":""}})]),_vm._m(2),_c('div',{staticClass:"dvantage_content"},[_c('img',{attrs:{"src":require('@/assets/img/about/mb(4).png'),"alt":""}})]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"dvantage_content"},[_c('img',{attrs:{"src":require('@/assets/img/about/mb(6).png'),"alt":""}})]),_vm._m(5),_c('div',{staticClass:"dvantage_content"},[_c('img',{attrs:{"src":require('@/assets/img/about/mb(5).png'),"alt":""}})])])])]),_c('my-foot',{attrs:{"id":"anchor_3"},on:{"changePage":_vm.changePage}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_top"},[_c('p',[_vm._v("关于青岛玉龙鼎盛")]),_c('span',[_vm._v("专业的资产管理公司")]),_c('div',{staticClass:"about_us"}),_c('div',{staticClass:"stencil_one"}),_c('div',{staticClass:"details"},[_vm._v(" 青岛玉龙鼎盛是一家专业的资产管理公司，致力于为中国的高成长企业及高净值人群提供全方位资本运营服务，公司自成立以来，各方面运作规范成熟，凭借丰富的资源与专业化服务，目前已与多家大型民企达成战略合作。通过产品的设计及完备的投后管理，帮助融资方实现股权、债权多样化的金融产品融资，为多家大型民企集团提供权益产品、私募债、定向融资工具、定向投资工具等多元化的融资服务。 ")]),_c('div',{staticClass:"sləʊp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage"},[_c('div',{staticClass:"advantage_left"},[_vm._v("青岛玉龙鼎盛优势")]),_c('div',{staticClass:"advantage_right"},[_vm._v("Advantage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dvantage_content1"},[_c('p',[_vm._v("合规风险管理")]),_c('div',{staticClass:"wear"}),_c('span',[_vm._v("公司建立覆盖业务全流程的合规管理制度和合规管理机制，有效识别合规风险，主动避免违规事件发生。内部大力推行合规文化建设，确保合规工作落实到位。 公司项目实施全程风险控制机制。主要风险控制贯穿项目筛选、投资决策、项目投资及投后管理的全过程。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dvantage_content1"},[_c('p',[_vm._v("投后管理")]),_c('div',{staticClass:"wear"}),_c('span',[_vm._v("公司投后管理实施定期汇报制度和重大、突发事项预警机制；公司对已投项目的退出事项，通过制定相应的退出计划，由各部门协调配合完成投资项目的顺利退出。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dvantage_content1"},[_c('p',[_vm._v("精细化运营")]),_c('div',{staticClass:"wear"}),_c('span',[_vm._v("建立专业的运营大数据系统，建立投资项目跟踪数据全方位指标体系。采用数字化运营管理机制，不断提高运营效率，为客户提供精准流畅的线上服务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dvantage_content1"},[_c('p',[_vm._v("金融创新能力")]),_c('div',{staticClass:"wear"}),_c('span',[_vm._v("能够充分利用市场各种投融资工具和策略，进行创新的整合和发展，运用互联网技术创新手段，以健全的风险管控体系为基础，为机构、企业与投资者搭建投融资平台，通过资产收益权转让、直接融资等金融产品、金融工具的创新，优化增量资金投向，促进实体经济发展。")])])
}]

export { render, staticRenderFns }