<template>
  <div class="about_index">
    <div class="banner"></div>
    <div class="content ">
      <div class="aboutIndex">
        <div class="content_top">
          <p>关于青岛玉龙鼎盛</p>
          <span>专业的资产管理公司</span>
          <div class="about_us"></div>
          <div class="stencil_one"></div>
          <div class="details">
            青岛玉龙鼎盛是一家专业的资产管理公司，致力于为中国的高成长企业及高净值人群提供全方位资本运营服务，公司自成立以来，各方面运作规范成熟，凭借丰富的资源与专业化服务，目前已与多家大型民企达成战略合作。通过产品的设计及完备的投后管理，帮助融资方实现股权、债权多样化的金融产品融资，为多家大型民企集团提供权益产品、私募债、定向融资工具、定向投资工具等多元化的融资服务。
          </div>
          <div class="sləʊp"></div>
        </div>
        <div class="advantage">
          <div class="advantage_left">青岛玉龙鼎盛优势</div>
          <div class="advantage_right">Advantage</div>
        </div>
        <i></i>

        <div class="dvantage">
            <div class="dvantage_content">
                <img :src="require('@/assets/img/about/mb(3).png')" alt="">
            </div>
            <div class="dvantage_content1">
                <p>合规风险管理</p>
                <div class="wear"></div>
                <span>公司建立覆盖业务全流程的合规管理制度和合规管理机制，有效识别合规风险，主动避免违规事件发生。内部大力推行合规文化建设，确保合规工作落实到位。
公司项目实施全程风险控制机制。主要风险控制贯穿项目筛选、投资决策、项目投资及投后管理的全过程。</span>
            </div>
            <div class="dvantage_content">
                <img :src="require('@/assets/img/about/mb(4).png')" alt="">
            </div>
            <div class="dvantage_content1">
                <p>投后管理</p>
                <div class="wear"></div>
                <span>公司投后管理实施定期汇报制度和重大、突发事项预警机制；公司对已投项目的退出事项，通过制定相应的退出计划，由各部门协调配合完成投资项目的顺利退出。</span>
            </div>
            <div class="dvantage_content1">
                <p>精细化运营</p>
                <div class="wear"></div>
                <span>建立专业的运营大数据系统，建立投资项目跟踪数据全方位指标体系。采用数字化运营管理机制，不断提高运营效率，为客户提供精准流畅的线上服务。</span>
            </div>
            <div class="dvantage_content">
                <img :src="require('@/assets/img/about/mb(6).png')" alt="">
            </div>
            <div class="dvantage_content1">
                <p>金融创新能力</p>
                <div class="wear"></div>
                <span>能够充分利用市场各种投融资工具和策略，进行创新的整合和发展，运用互联网技术创新手段，以健全的风险管控体系为基础，为机构、企业与投资者搭建投融资平台，通过资产收益权转让、直接融资等金融产品、金融工具的创新，优化增量资金投向，促进实体经济发展。</span>
            </div>
            <div class="dvantage_content">
                <img :src="require('@/assets/img/about/mb(5).png')" alt="">
            </div>
            
        </div>
      </div>
    </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import '../../assets/css/about/index.scss'
import myFoot from '@/components/myFoot/index.vue'
export default {
  name: 'about',
  data() {
    return {}
  },
  components: {
    myFoot,
  },
  methods: {
    changePage(i) {
      this.$store.commit('changePage', i)
    },
  },
}
</script>
